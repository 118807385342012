import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import './App.css';
import Development from "./components/Development";
import Footer from "./components/Footer";
import Illustration from "./components/Illustration";
import Nav from "./components/Nav";
import Portfolio from "./components/Portfolio";
import Profile from "./components/Profile";
import Resume from "./components/Resume";
import styled from "styled-components";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/",
        element: <Development />,
      },
      {
        path: "/illustration",
        element: <Illustration />,
      },
    ],
  },
]);

const Hr = styled.hr`
  border-top: 1px #8c8b8b;
  width: 80%;
`;


function Root() {
  return (
    <>
      <Nav />
      <Profile />
      <Resume />
      <Portfolio />
      <Outlet />
    <a href="#top">🚀</a>
      <Hr />
      <Footer />
    </>
  );
}

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;

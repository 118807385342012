import profilePicture from "../assets/images/profile_picture.png";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  font-family: "Cormorant Garamond", serif;
  font-weight: 500;
  font-style: normal;

  .pic img {
    height: 700px;
  }

  .info ul {
    list-style: none;
    padding-left: 0;
    margin-top: 88px;
  }

  .info li {
    &:not(:last-child) {
      margin: 0 0 40px 0;
    }
  }

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;

    li {
      align-items: center;
    }

    img {
      object-fit: cover;
      width: 100%;
    }
  }
`;

const StyledNavLink = styled.a`
  color: #233a67;
  text-decoration: none;
`;

const Content = styled.div<{ $justifyText?: boolean }>`
  padding: 0 3em;
  text-align: ${(props) => (props.$justifyText ? "justify" : "center")};
  font-size: 1.1em;
  line-height: 1.8em;
`;

const items = [
  {
    title: "Bio",
    content: (
      <>
        <p>
          Hello, thank you for visiting my website! My name is Ai, I am a
          Software Engineer based in London.
        </p>
        <p>
          I studied computer science at the University of Tsukuba during my bachelor's degree,
          where I wrote my bachelor's thesis on NLP-related topics and developed several web applications.
          Some of the projects I have worked on are showcased in the{" "}
          <StyledNavLink href="#portfolio">Portfolio</StyledNavLink> section as
          well as on my{" "}
          <StyledNavLink href="https://github.com/aimiyuki">
            GitHub
          </StyledNavLink>
          .
        </p>
      </>
    ),

    justify: true,
  },
];

function Profile() {
  return (
    <Container>
      <div className="pic">
        <img src={profilePicture} alt="profile" />
      </div>
      <div className="info">
        <ul>
          {items.map((item, index) => {
            return (
              <li key={index}>
                <h3>{item.title}</h3>
                <Content $justifyText={item.justify}>{item.content}</Content>
              </li>
            );
          })}
        </ul>
      </div>
    </Container>
  );
}

export default Profile;

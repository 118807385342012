import styled from 'styled-components';
import inkbox from "../assets/images/inkbox.png";
import bijo from "../assets/images/bijo.png";
import millamore from "../assets/images/millamore.png";
import shibui from "../assets/images/shibui.png";
import glossier from "../assets/images/glossier.png";
import matchado from "../assets/images/matchado.png";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 80px;
  align-items: center;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const Media = styled.div`
  margin-top: 20px;
  overflow: hidden;
  height: 300px;
  width: 300px;
  flex: 0 0 30%;

  @media (max-width: 1200px) {
    flex: 0 0 50%;
  }

  img {
    object-fit: contain;
    height: 300px;
    border-radius: 2%;
  }
`;

const items = [
  {
    url: "https://inkbox.com/artists",
    title: "Inkbox",
    imgURL: inkbox,
    imgAlt: "Inkbox",
  },
  {
    url: "https://bijo.paris/en",
    title: "Bijo",
    imgURL: bijo,
    imgAlt: "bijo",
  },
  {
    url: "https://en.milamorejewelry.com/",
    title: "Millamore",
    imgURL: millamore,
    imgAlt: "millamore",
  },
  {
    url: "https://www.shibui-italia.com/",
    title: "shibui",
    imgURL: shibui,
    imgAlt: "shibui",
  },
  {
    url: "https://uk.glossier.com/",
    title: "Glossier",
    imgURL: glossier,
    imgAlt: "glossier",
  },
  {
    url: "https://matchado.co.uk/",
    title: "matchado",
    imgURL: matchado,
    imgAlt: "matchado",
  },
];

function Illustration() {
  return (
    <>
      <Container>
        {items.map((item, index) => {
          return (
            <Media key={index}>
              <a href={item.url}>
                <img src={item.imgURL} alt={item.imgAlt} />
              </a>
            </Media>
          );
        })}
      </Container>
    </>
  );
};

export default Illustration;

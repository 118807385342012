import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Pink from "./Pink";
import UnderlinedLink from "./UnderlinedLink";

const Container = styled.div`
  margin-top: 50px;

  .nav-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
`;

const Ul = styled.ul`
      padding: 0px;
`

type PortfolioNavItemProps = {
  url: string;
  label: string;
  className?: string;
};

function RawPortfolioNavItem({ url, label, className }: PortfolioNavItemProps) {
  return (
    <li className={className}>
      <h3>
        <UnderlinedLink to={url} borderSize="0.7px" color="#000000" as={NavLink}>
          {label}
        </UnderlinedLink>
      </h3>
    </li>
  );
}
const PortfolioNavItem = styled(RawPortfolioNavItem)`
  display: inline-block;
  &:not(:last-child) {
    margin-right: 50px;
  }
`;

function Portfolio() {
  return (
    <Container>
      <Pink>
        <h2 id="portfolio">Portfolio</h2>
      </Pink>
      <div className="nav-wrapper">
        <Ul>
          <PortfolioNavItem url="/" label="Development" />
          <PortfolioNavItem url="/illustration" label="Illustration" />
        </Ul>
      </div>
    </Container>
  );
}

export default Portfolio;

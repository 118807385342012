import styled from "styled-components";

type UnderlinedLinkProps = {
  color?: string;
  borderSize?: string;
};

const UnderlinedLink = styled.a<UnderlinedLinkProps>`
  text-decoration: none;
  color: ${(p) => p.color || "#233a67"};
  display: inline-block;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    display: block;
    width: 0;
    border-bottom: ${(p) => p.borderSize || "0.3px"} solid;
    margin: 0 auto;
    transition: 0.3s;
  }

  &:hover::after,
  &.active::after {
    width: 90%;
    left: 5%;
  }
`;

export default UnderlinedLink;

import styled from "styled-components";
import Socials from "./Socials";

const Container = styled.div`
  padding: 0 50px;
  align-items: center;

  .items-wrapper {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0 20px 0;

    @media (max-width: 600px) {
      display: flex;
      flex-direction: column;
    }
  }
`;

function Footer() {
  return (
    <Container>
      <div className="items-wrapper">
        <div className="item">
          <p>© 2024 Ai Miyuki. All Rights Reserved.</p>
        </div>
        <div className="item">
          <Socials />
        </div>
      </div>
    </Container>
  );
}

export default Footer;

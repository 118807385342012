import styled from "styled-components";

const PinkBackground = styled.div`
  position: relative;

  & > div {
    display: flex;
    justify-content: center;
  }

  .colored {
    aspect-ratio: 1 / 1;
    background: rgb(224, 204, 244);
    background: radial-gradient(
      ellipse,
      rgba(224, 204, 244, 1) 0%,
      rgba(243, 213, 200, 1) 69%,
      rgba(255, 248, 245, 1) 100%
    );
    border-radius: 50%;
    position: absolute;
    top: -30px;
    filter: blur(50px);
    will-change: filter;
    width: min(100%, 250px);
    z-index: -1;
  }
`;

type PinkProps = {
  children: React.ReactNode;
};

function Pink({ children }: PinkProps) {
  return (
    <PinkBackground>
      <div>
        <div className="colored"></div>
      </div>
      {children}
    </PinkBackground>
  );
}

export default Pink;

import styled from "styled-components";

const TitlebarWrapper = styled.div`
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #ebebeb, color-stop(1, #d5d5d5))
  );
  background: -webkit-linear-gradient(top, #ebebeb, #d5d5d5);
  background: -moz-linear-gradient(top, #ebebeb, #d5d5d5);
  background: -ms-linear-gradient(top, #ebebeb, #d5d5d5);
  background: -o-linear-gradient(top, #ebebeb, #d5d5d5);
  background: linear-gradient(top, #ebebeb, #d5d5d5);
  color: #4d494d;
  font-size: 11pt;
  line-height: 20px;
  text-align: center;
  width: 100%;
  height: 20px;
  border-top: 1px solid #f3f1f3;
  border-bottom: 1px solid #b1aeb1;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  cursor: default;

  .buttons {
    padding-left: 8px;
    padding-top: 3px;
    float: left;
    line-height: 0px;
    &:hover a {
      visibility: visible;
    }
  }
`;

type TitleBarButtonWrapperProps = {
  color: string;
  bgColor: string;
  borderColor: string;
};

const TitleBarButtonWrapper = styled.div<TitleBarButtonWrapperProps>`
  background: ${(props) => props.bgColor};
  font-size: 9pt;
  line-height: 11px;
  margin-left: 4px;
  width: 11px;
  height: 11px;
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 50%;
  display: inline-block;
  a {
    visibility: hidden;
    &:hover {
      visibility: visible;
    }
  }
`;

function TitleBarButton({
  color,
  bgColor,
  borderColor,
  char,
}: TitleBarButtonWrapperProps & { char: string }) {
  return (
    <TitleBarButtonWrapper
      color={color}
      bgColor={bgColor}
      borderColor={borderColor}
    >
      <a href="/#">
        <span>
          <strong>{char}</strong>
        </span>
      </a>
    </TitleBarButtonWrapper>
  );
}

type MacLikeTitlebarProps = {
  title: string;
};

function MacLikeTitlebar({ title }: MacLikeTitlebarProps) {
  return (
    <TitlebarWrapper>
      <div className="buttons">
        <TitleBarButton
          color="#4e0002"
          bgColor="#ff5c5c"
          borderColor="#e33e41"
          char="x"
        />
        <TitleBarButton
          color="#5a2607"
          bgColor="#ffbd4c"
          borderColor="#e09e3e"
          char="-"
        />
        <TitleBarButton
          color="#003107"
          bgColor="#00ca56"
          borderColor="#14ae46"
          char="+"
        />
      </div>
      <div style={{ textAlign: "center" }}>{title}</div>
    </TitlebarWrapper>
  );
}

const MacLikeWindowWrapper = styled.div`
  background: #fff;
  width: 100%;
  padding-bottom: 1em;
  border: 1px solid #acacac;
  border-radius: 6px;
  box-shadow: 0px 0px 20px #acacac;
`;

type WindowProps = {
  title: string;
  subtitle: string;
  children: React.ReactNode;
};

export default function MacLikeWindow({
  title,
  subtitle,
  children,
}: WindowProps) {
  return (
    <MacLikeWindowWrapper>
      <MacLikeTitlebar title={title} />
      <h3 style={{ marginTop: "20px" }}>{subtitle}</h3>
      {children}
    </MacLikeWindowWrapper>
  );
}

import styled from "styled-components";
import MacLikeWindow from "./MacLikeWindow";
import ULink from "./UnderlinedLink";

const Container = styled.div`
  display: flex;
  width: 60vw;
  flex-direction: column;
  gap: 4em;
  align-items: center;
  margin: 40px auto;

  p {
    line-height: 1.7em;
    text-align: left;
    padding: 0 1em;
  }

  .links {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
`;

const items = [
  {
    affiliation: "University of Tsukuba",
    title: "Same Language Translator (SLT)",
    content: (
      <>
        SLT is a software that reformulates complex Japanese expressions into
        plain Japanese. SLT uses various NLP techniques to find difficult parts
        of the input sentence and simplify them. It is written in Python using{" "}
        <ULink href="https://spacy.io/">Spacy</ULink> for tokenization and
        dependency parsing, and{" "}
        <ULink href="https://flask.palletsprojects.com/en/3.0.x/">Flask</ULink>{" "}
        for the web server. This software was built as part of my Bachelor's
        thesis at the University of Tsukuba.
      </>
    ),
    links: [
      {
        url: "https://slt.aimiyuki.me/?sentence=%E6%9C%AC%E6%97%A5%E3%81%AF%E5%8F%8B%E4%BA%BA%E3%81%A8%E3%81%8A%E9%A3%9F%E4%BA%8B%E3%81%97%E3%81%9F%E5%BE%8C%E3%81%AB%E3%80%81%E5%A4%A7%E5%AD%A6%E3%81%AB%E5%8F%82%E3%82%8A%E3%81%BE%E3%81%97%E3%81%9F%E3%80%82",
        text: "View demo",
      },
      {
        url: "https://github.com/aimiyuki/slt-prototype",
        text: "View repo",
      },
      {
        url: "https://github.com/tmicltw/slt-prototype/releases/download/v1.0/bachelor-thesis.pdf",
        text: "Read thesis",
      },
    ],
  },
  {
    affiliation: "Akord",
    title: "Arweave Storage Price",
    content: (
      <>
        The <ULink href="https://www.arweave.org/">Arweave blockchain</ULink>{" "}
        uses a dynamic pricing model to store data. This website allows users to
        view the current price of storing data. This also implements a Firebase
        function to peridoically save the price of storing data on Arweave. The
        historical data is then displayed as a chart on the website. This
        project was built in{" "}
        <ULink href="https://www.typescriptlang.org/">TypeScript</ULink> using{" "}
        <ULink href="https://react.dev/">ReactJS</ULink>,{" "}
        <ULink href="https://www.chartjs.org/">ChartJS</ULink>, and{" "}
        <ULink href="https://firebase.google.com/">Firebase</ULink>.
      </>
    ),
    links: [
      {
        url: "https://arweave-fees.web.app/",
        text: "View website",
      },
      {
        url: "https://github.com/aimiyuki/arweave-fees",
        text: "View repo",
      },
    ],
  },
  {
    affiliation: "Akord",
    title: "Scaffold-ETH Arweave support",
    content: (
      <>
        <ULink href="https://scaffoldeth.io/">Scaffold ETH</ULink> is a frontend
        environment that helps developers build frontend for blockchain
        applications. Scaffold ETH originally only supported{" "}
        <ULink href="https://ipfs.tech/">IPFS</ULink>. I added{" "}
        <ULink href="https://www.arweave.org/">Arweave</ULink> and{" "}
        <ULink href="https://akord.com/">Akord</ULink> support, allowing users
        to choose where and how they want to upload their application data.
      </>
    ),
    links: [
      {
        url: "https://github.com/aimiyuki/scaffold-eth/tree/add-akord-mint-nft-support",
        text: "View repo",
      },
    ],
  },
  {
    affiliation: "ETHGlobal Tokyo",
    title: "Multi-chain copy trading protocol",
    content: (
      <>
        The EchoKitty project is a trustless and permissionless protocol that
        allows users to copy trade any other wallet address. This project was
        built as part of the ETHGlobal Tokyo hackathon.
      </>
    ),
    links: [
      {
        url: "https://ethglobal.com/showcase/echokitty-77ysy",
        text: "View more",
      },
    ],
  },
];

function Development() {
  return (
    <>
      <Container>
        {items.map((item, index) => {
          return (
            <MacLikeWindow
              key={index}
              title={item.affiliation}
              subtitle={item.title}
            >
              <p>{item.content}</p>
              <div className="links">
                {item.links.map((link, index) => {
                  return (
                    <ULink key={index} href={link.url}>
                      {link.text}
                    </ULink>
                  );
                })}
              </div>
            </MacLikeWindow>
          );
        })}
      </Container>
    </>
  );
}

export default Development;

import { useState } from 'react';
import styled from 'styled-components';
import RawHamburger from "../assets/images/hamburger.png";

const NavBarContainer = styled.div`
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  .menu-icon {
    display: none;
  }

  & ul {
    display: flex;
    justify-content: flex-end;
    list-style-type: none;

    & li {
      margin-right: 50px;
    }
  }

  @media (max-width: 600px) {
    .menu-icon {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
`;

const Hamburger = styled.img`
  height: 50px;
`

const NavBar = styled.nav`
  padding: 20px 0 0 0;
`;

const StyledNavLink = styled.a`
  font-family: 'New Romantics';
  font-size: 16px;
  font-weight: 400;
  color: #2f234f;
  text-decoration: none;

  &.active {
    color: #574c4c;
    font-weight: 500;
    position: relative;
  }

  &.a.active::after {
      content: '';
      position: absolute;
      bottom: -4px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #574c4c;
  }
`;

const NavElements = styled.div`
@media (max-width: 600px) {
  position: absolute;
  right: 0;
  top: 60px;
  background-color: #F5F5F7;
  width: 0;
  height: calc(100vh - 60px);
  transition: all 0.3s ease-in;
  overflow: hidden;
  opacity: 60%;

  &.active {
    width: 270px;
  }

  & ul {
    display: flex;
    flex-direction: column;
  }

  & ul li {
    margin-top: 22px;
  }
}
`;

const items = [
  {
    label: "Home",
    url: "/"
  },
  {
    label: "Resume",
    url: "#cv"
  },
  {
    label: "Portfolio",
    url: "#portfolio"
  },
  {
    label: "GitHub",
    url: "https://github.com/aimiyuki"
  }
]

function Nav() {
  const [showNavbar, setShowNavbar] = useState(false);

  const handleShowNavBar = () => {
    setShowNavbar(!showNavbar)
  }

  return (
    <NavBar>
      <NavBarContainer>
        <div className="menu-icon" onClick={handleShowNavBar}>
          <Hamburger src={RawHamburger} />
        </div>
        <NavElements className={`${showNavbar && 'active'}`}>
          <ul>
            {items.map((item, index) => {
              return (
                <li key={index}>
                  <StyledNavLink key={index} href={item.url}>
                    <h3>{item.label}</h3>
                  </StyledNavLink>
                </li>
              );
            })
            }
          </ul>
        </NavElements>
      </NavBarContainer>
    </NavBar>
  )
};

export default Nav;

import styled from 'styled-components';

const Container = styled.div`
  float: right;

  ul {
    padding-left: 0;
  }

  .footer-social-icons {
    width: 350px;
    display: block;
  }

  .social-icons li {
    vertical-align: top;
    display: inline;
  }

  .social-icons a {
    color: #242629;
  }

  .fa {
    padding: 10px 14px;
  }
`;

const items = [
  {
    url: "https://github.com/aimiyuki",
    name: "github",
  },
  {
    url: "https://www.linkedin.com/in/ai-miyuki/",
    name: "linkedin",
  },
  {
    url: "https://twitter.com/tmicltw",
    name: "twitter",
  },
  {
    url: "https://www.instagram.com/hey_ai/",
    name: "instagram",
  },
];

function Socials() {
  return (
    <>
      <Container>
        <ul className="social-icons">
          {items.map((item, index) => {
            return (
              <li key={index}>
                <a href={item.url} className="social-icon">
                  <i className={`fa fa-${item.name}`}></i>
                </a>
              </li>
            );
          })}
        </ul>
      </Container>
    </>
  );
}

export default Socials;
